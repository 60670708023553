import React, { useState, useRef, useCallback } from "react";
import ReactSlidedown from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import logo from "./logo.svg";
import {
  getHomeUrl,
  getBlogUrl,
  getContactUrl,
  getCaseStudiesUrl,
  getVacanciesUrl,
  getServicesUrl,
  getMondayAppsDevelopmentUrl,
  getProductDevelopmentUrl,
  getTeamExtensionUrl,
} from "../../utils/urls";
import { Link } from "../Link";
import { useClickOutsideClose } from "../../hooks/useClickOutsideClose";
import cn from "classnames";
import { Hamburger } from "./Hamburger";
import * as styles from "./Header.module.scss";
import * as sectionStyles from "../Section.module.scss";
import { Button } from "../Button";
import { ArrowDownIcon } from "../Svg/ArrowDownIcon";

type Props = {
  contactUrl?: string;
};

export const Header: React.FC<Props> = ({ contactUrl = getContactUrl() }) => {
  const [sideOpen, setSideOpen] = useState(false);
  const [isServicesShown, shownServices] = useState(false);

  const close = useCallback(() => setSideOpen(false), [setSideOpen]);
  const menuRef = useRef(null);

  useClickOutsideClose(menuRef, sideOpen, close);

  const toggle = useCallback(
    () => setSideOpen(!sideOpen),
    [setSideOpen, sideOpen]
  );

  const handleServicesClick = () => {
    shownServices(!isServicesShown);
  };

  return (
    <>
      <header
        className={cn(styles.header, {
          [styles.open]: sideOpen,
        })}
      >
        {/* <HelpUkraine /> */}
        <div className={cn(sectionStyles.container, styles.headerContainer)}>
          <div className={styles.headerrow}>
            <Link className={styles.brand} to={getHomeUrl()}>
              <img alt="LeanyLabs" src={logo} />
            </Link>
            <div ref={menuRef} className={styles.navwrap}>
              <Hamburger
                className={styles.burger}
                open={sideOpen}
                onClick={toggle}
              />
              <nav className={styles.nav}>
                <ul>
                  <li>
                    {sideOpen ? (
                      <>
                        <button
                          type="button"
                          className={styles.navItem}
                          onClick={handleServicesClick}
                        >
                          Services
                          <ArrowDownIcon />
                        </button>
                        <ReactSlidedown>
                          {isServicesShown && (
                            <ul className={styles.navSubitemList}>
                              <li>
                                <Link
                                  to={getMondayAppsDevelopmentUrl()}
                                  className={styles.navSubitem}
                                  onClick={close}
                                >
                                  monday.com Apps
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={getProductDevelopmentUrl()}
                                  className={styles.navSubitem}
                                  onClick={close}
                                >
                                  Product Development
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={getTeamExtensionUrl()}
                                  className={styles.navSubitem}
                                  onClick={close}
                                >
                                  Team Extension
                                </Link>
                              </li>
                            </ul>
                          )}
                        </ReactSlidedown>
                      </>
                    ) : (
                      <Link
                        to={getServicesUrl()}
                        className={styles.navItem}
                        onClick={close}
                      >
                        Services
                      </Link>
                    )}
                  </li>

                  <li className={styles.split}>
                    <Link
                      to={getCaseStudiesUrl()}
                      className={styles.navItem}
                      onClick={close}
                    >
                      Case Studies
                    </Link>
                  </li>
                  <li className={styles.split}>
                    <Link
                      to={getBlogUrl()}
                      className={styles.navItem}
                      onClick={close}
                    >
                      Blog
                    </Link>
                  </li>
                  {/* <li className={styles.split}>
                    <Link
                      to={getVacanciesUrl()}
                      className={styles.navItem}
                      onClick={close}
                    >
                      Career
                    </Link>
                  </li> */}
                  <li className={styles.contact}>
                    <Button
                      to={contactUrl}
                      className={styles.navItem}
                      onClick={close}
                    >
                      Contact Us
                    </Button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
